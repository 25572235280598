<template>
  <div class="application-container">
    <!-- navbar -->
    <qb-navbar title="课程报名"></qb-navbar>
    <!-- title -->
    <qb-title title="课程报名" subtitle="Quanbang Group Application"></qb-title>
    <!-- form -->
    <application-form></application-form>
  </div>
</template>

<script>
import ApplicationForm from './components/application-form.vue'
export default {
  name: 'Application',
  components: {
    ApplicationForm
  }
}
</script>

<style lang="less" scoped></style>
