<template>
  <div class="form-container">
    <van-form @submit="onSubmit" ref="form">
      <van-field
        v-model="formData.username"
        type="text"
        name="用户名"
        label="用户名"
        placeholder="点击输入姓名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="formData.phoneNumber"
        type="text"
        name="手机号/电话"
        label="手机号/电话："
        placeholder="点击输入手机号/电话"
        :rules="[{ required: true, message: '请填写手机号/电话' }]"
      />
      <van-field
        v-model="formData.companyName"
        type="text"
        name="公司名称"
        label="公司名称："
        placeholder="点击输入公司名称"
        :rules="[{ required: true, message: '请填写公司名称' }]"
      />
      <van-field
        v-model="formData.companyAddress"
        type="password"
        name="公司地址"
        label="公司地址："
        placeholder="请输入公司地址"
        :rules="[{ required: true, message: '请填写密公司地址' }]"
      />
      <van-field
        readonly
        clickable
        name="课程场次"
        :value="formData.session"
        label="课程场次："
        placeholder="点击选择课程场次"
        @click="showPicker = true"
        right-icon="arrow"
      >
      </van-field>
      <div style="margin: 16px;">
        <van-button round block color="#010343" type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'Form',
  data() {
    return {
      formData: {
        username: '',
        phoneNumber: '',
        companyName: '',
        companyAddress: '',
        session: ''
      },
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      showPicker: false
    }
  },
  methods: {
    async onSubmit(values) {
      console.log(this.$refs.form)
    },
    onConfirm(value) {
      this.formData.session = value
      this.showPicker = false
    }
  }
}
</script>

<style lang="less" scoped>
.form-container {
  position: relative;
  padding: 43px 20px 0;
  background: #f7f9fd;
  .icon {
    position: absolute;
    top: 250px;
    left: 300px;
  }
  .van-button--round {
    margin-top: 20px;
    border-radius: 4px;
  }
}
</style>
